<template>
  <v-col sm="12">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="selectTab"
    >
      <img src="@/assets/icons/terminal-arrow-back.svg" alt="" />
      <span class="tw-ml-4">All Pilots</span>
    </button>

    <v-col
      sm="12"
      class="tw-bg-white tw-mt-9 tw-rounded-xl tw-pl-16 tw-py-10 tw-pr-40 tw-w-full"
    >
      <div
        class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"
      >
        <p class="edit-header">Edit Pilot</p>
        <img src="@/assets/icons/del-blue.svg" alt="" />
      </div>
      <v-row class="upload-div tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Upload Photo</p>
            <p class="label-info">Choose an image as a profile</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <button
            class="button-logo-input"
            type="button"
            @click="onLogoFile()"
            :class="image ? 'border-0' : ''"
          >
            <v-progress-circular
              :size="100"
              :width="3"
              :color="color"
              indeterminate
              style="position: absolute;"
              v-if="logoImageLoading"
            ></v-progress-circular>

            <img :src="image" alt="" class="button-logo-img" v-if="logoImage" />
            <img
              src="../../../assets/person.jpg"
              alt=""
              class="button-logo-img"
              v-else
            />
          </button>

          <input
            type="file"
            ref="logoImage"
            accept="image/*"
            style="display: none"
            @change="onLogoImagePicked"
          />
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle's Type</p>
            <p class="label-info">
              Choose the Type of the Vehicle.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Type"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="vehicleTypes"
                label="Choose Type"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
<v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle's Class</p>
            <p class="label-info">
              Choose the Type of the Vehicle.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Vehicle's Class"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="vehicleClasses"
                label="Choose Class"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

<v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle Number</p>
            <p class="label-info">Fill in the plate Number of the Vehicle</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Vehicle Number"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="LAG - 345 - UH"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

<v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle ID</p>
            <p class="label-info">The ID given to the Vehicle</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Vehicle ID"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="BUS - 345"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Air Condition</p>
            <p class="label-info">
              Choose Vehicle Air Condition Type
            </p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
              name="Air Condition"
              rules="required"
              v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                  placeholder="AC TYPE"
                  solo
                  append-icon="mdi-chevron-down"
                  hide-details
                  v-model="vehicleAcType"
                  :items="vehicleAcTypes"
                  type="text"
                  required
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>


      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle Video</p>
            <p class="label-info">Choose vehicle video streaming type </p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
              name="Vehicle Video"
              rules="required"
              v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                  placeholder="Choose video type"
                  solo
                  append-icon="mdi-chevron-down"
                  hide-details
                  type="text"
                  :items="vehicleVideoTypes"
                  v-model="vehicleVideoType"
                  required
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

<v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle Brand Name</p>
            <p class="label-info">
              Choose the Brand Name of the Vehicle
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Brand Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Telsa"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

<v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle's Capacity</p>
            <p class="label-info">
              Choose the no. of passenger the vehicle can accomodate
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Vehicle's Capacity"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="vehicleCaps"
                label="Choose Class"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Terminal</p>
            <p class="label-info">
              Choose the Terminal the Vehicle is located.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="terminals"
                label="Choose Terminal"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Vehicle's Route</p>
            <p class="label-info">
              Choose the Route of the Fleet.
            </p>
          </div>
        </v-col>

        <v-col sm="8" class="">
          <v-row class="tw-justify-between tw-items-center">
            <v-col sm="5">
              <ValidationProvider
                name="From"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                    :items="routes"
                    label="From"
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    item-value="name"
                    outlined
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

            </v-col>
            <v-col sm="2" class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div class="tw-mb-5">
                <img src="@/assets/icons/line.svg" alt="">
              </div>
            </v-col>
            <v-col sm="5">
              <ValidationProvider
                name="To"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                    :items="routes"
                    label="To"
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    item-value="name"
                    outlined
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

            </v-col>

          </v-row>
          
        </v-col>
      </v-row>


      <div
        class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"
      >
        <button class="first-btn">
          Cancel
        </button>
        <button class="second-btn">
          Done
        </button>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary
} from "../../../services/api/APIService";
export default {
  name: "EditBooking",
  data() {
    return {
      country: "",
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      buses: [{ name: "IKY 367 AE" }, { name: "IKY 847 GB" }],
      vehicleTypes: [{ name: "BUS" }, { name: "VAN" }],
      vehicleClasses: [{ name: 'First Class' }, { name: 'Economy Class' }],
      vehicleCaps: [{ name: 90 }, { name: 24 }],
      terminals: [{ name: 'Lekki Terminals' }, { name: 'Apapa Terminals' }],
      routes: [{ name: 'LAG' }, { name: 'PH' }, { name: 'ABJ' }, { name: 'KAD' },],
      vehicleAcType:"",
      vehicleAcTypes:["A/C","NO-A/C"],
      vehicleVideoType:"",
      vehicleVideoTypes:["VIDEO","NO-VIDEO"]
    };
  },
  methods: {
    selectTab() {
      this.$store.dispatch("fleetComponent/setCurrentComponent", "allFleets");
      sessionStorage.setItem("fleetComponent", "allFleets");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.logoImageUrl = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.country
        };
        console.log(data);
        const response = await getAllStatesByCountry(data);
        console.log(response);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      try {
        this.getCitiesLoading = true;
        let data = {
          country: this.country,
          state: this.state
        };

        const response = await getAllCitiesInAState(data);
        console.log(response);
        this.cities = response.data.data;
        this.getCitiesLoading = false;
      } catch (err) {
        console.log(err.response);
        this.cities = [];
        this.getCitiesLoading = false;
      }
    }
  },
  mounted() {
    this.getCountries();
  }
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
