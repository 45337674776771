<template>
  <!-- <router-view ></router-view> -->
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "../terminal/ViewTerminalDashboard.vue";
import AllFleets from "./AllFleetDashboard";
import SingleFleet from "./SingleFleet";
import EditFleet from "./EditFleet";
import AddFleet from "./AddFleet";
import { mapGetters } from "vuex";

export default {
  name: "ViewFleetDashboardRoute",
  computed: {
    ...mapGetters("fleetComponent", ["currentComponent"])
  },
  methods: {
    displayTerminalComponent() {
      console.log("koll", this.currentComponent)
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allFleets") {
        console.log("i am here")
        return AllFleets;
      } else if (component === "singleFleet") {
        return SingleFleet;
      } else if (component === "editFleet") {
        return EditFleet;
      } else if (component === "addFleet") {
        return AddFleet;
      }
    }
  },
  created() {
    let component = sessionStorage.getItem("fleetComponent");

    if (!component) {
      sessionStorage.setItem("fleetComponent", "allFleets");
      this.$store.dispatch("fleetComponent/setCurrentComponent", "allFleets");
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch("fleetComponent/setCurrentComponent", component);
      this.displayTerminalComponent();
    }
  }
};
</script>

<style lang="scss" scoped></style>
