<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
        <icon-and-text
          :icon="addfleet"
          title="New Vehicle"
          desc="Add a Vehicle"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
          @clickAction="newTerminalTab"
        />
        <icon-and-text
          :icon="allfleet"
          :title="fleets.length.toString()"
          desc="All fleet"
          :width="$vuetify.breakpoint.mdAndUp ? '25%': '100%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="getMostRecent.vehicleTagNumber"
          labelOne="Plate Number: "
          :valueOne="getMostRecent.vehicleRegNumber"
          labelThree="Terminal: "
          :valueThree="getMostRecent.terminalName"
          labelTwo="Class: "
          :valueTwo="getMostRecent.vehicleClass"
          :width="$vuetify.breakpoint.mdAndUp ? '48%': '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px' : 'auto'"
        />
      </div>

      <div class="
          tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8
          table-container-div
          tw-px-1 tw-pl-8"
        style="background: white">
        <div class="table-container tw-pr-8">
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between lg:tw-items-center">
            <p class="table-header" :style="{ color: companyColor }">
              All Fleets
            </p>
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div
                class="mr-2"
                style=" border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08);">
                <v-text-field
                  placeholder="Enter vehicle number"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            :sort-by="[]"
            height="available"
            :hide-default-footer="fleets.length <= 10"
            :items="fleets"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="selectSingle"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.vehicleImage>
              <span class="status">Image</span>
            </template>
            <template v-slot:[`item.vehicleImage`]="{ item }">
              <img
                :src="item.vehicleImage"
                alt="vehicle"
                v-if="item.vehicleImage"
              />
              <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
              >
                <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                  {{ item.vehicleBrand[0] }}
                </p>
              </div>
            </template>
            <template #header.vehicleType>
              <span class="status">Type</span>
            </template>
            <template v-slot:[`item.vehicleType`]="{ item }">
              <span class="td-style text-capitalize">{{ item.vehicleType }}</span>
            </template>
            <template #header.vehicleClass>
              <span class="status">Class</span>
            </template>
            <template v-slot:[`item.vehicleClass`]="{ item }">
              <span class="td-style text-capitalize">{{ item.vehicleClass }}</span>
            </template>
            <template #header.vehicleRegNumber>
              <span class="status">Vehicle Number</span>
            </template>
            <template v-slot:[`item.vehicleRegNumber`]="{ item }">
              <span class="td-style">{{ item.vehicleRegNumber }}</span>
            </template>
            <template #header.vehicleTagNumber>
              <span class="status">TAG NUMBER</span>
            </template>
            <template v-slot:[`item.vehicleTagNumber`]="{ item }">
              <span class="td-style">{{ item.vehicleTagNumber }}</span>
            </template>
            <template #header.vehicleBrand>
              <span class="status">Brand Name</span>
            </template>
            <template v-slot:[`item.vehicleBrand`]="{ item }">
              <span class="td-style text-capitalize">{{ item.vehicleBrand }}</span>
            </template>
            <template #header.vehicleCapacity>
              <span class="status">Capacity</span>
            </template>
            <template v-slot:[`item.vehicleCapacity`]="{ item }">
              <span class="td-style">{{ item.vehicleCapacity }}</span>
            </template>
            <template #header.terminalName>
              <span class="status">Terminal</span>
            </template>
            <template v-slot:[`item.terminalName`]="{ item }">
              <span class="td-style text-capitalize">{{ item.terminalName }}</span>
            </template>

              <template #header.status>
                  <span class="status">Status</span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                  <span class="td-style">{{ item.status }}</span>
              </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                    <v-list-item @click="handleTransferModal(item)">
                        <v-list-item-title
                                style="color: black"
                                class="tw-flex-row tw-flex"
                        >
                            <v-icon
                                    class="tw-mr-2"
                            >mdi-transfer</v-icon>
                            Transfer
                        </v-list-item-title>
                    </v-list-item>
                  <v-list-item @click="handleDeleteModal(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
      <delete-modal
        :dialog="showDeleteModal"
        description="Are you sure you want to Delete this Fleet"
        @close="handleDeleteModal"
        @deleteAction="handleDeleteFleet"
      />
        <modal section="transfer-vehicle" :dialog="showTransferModal"
               :color="companyColor" @close="closeTransferModal"
               :fleetToTransfer="fleetToTransfer" @transferFleet="transferFleet" :loading="modalLoading"/>
    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import addfleet from "@/assets/icons/addfleet.png";
import allfleet from "@/assets/icons/allfleets.png";
import locationImg from "@/assets/model.jpg";
import {
    deleteFleet,
    getAllCompanyFleets, transferFleet,
} from "@/services/api/APIService";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
import dayjs from "dayjs";
import Modal from "../../../components/reuseables/Modal.vue";

export default {
  name: "AllFleetDashboard",
  components: {Modal, DeleteModal, IconAndText, ImageAndTextTwo, Loader },
  data() {
    return {
      addfleet,
      allfleet,
      locationImg,
      noImage: false,
      companyData: {},
      fleets: [],
      companyColor: "#004AAD",
      loading: false,
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "vehicleImage" },
        { text: "", value: "vehicleType" },
        { text: "", value: "vehicleClass" },
        { text: "", value: "vehicleRegNumber" },
        { text: "", value: "vehicleTagNumber" },
        { text: "", value: "vehicleBrand" },
        { text: "", value: "vehicleCapacity" },
        { text: "", value: "terminalName" },
          { text: "", value: "status" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Fleet will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      showDeleteModal: false,
      companyId: null,
      fleetId: null,
      showTransferModal : false,
      fleetToTransfer:null,
      modalLoading: false
    };
  },
  computed: {
    getMostRecent() {
      if (this.fleets.length) {
        return this.fleets[0];
      } else {
        return "No fleet yet";
      }
    },
  },
  methods: {
    selectEditTab() {
      this.$store.dispatch("fleetComponent/setCurrentComponent", "editFleet");
      sessionStorage.setItem("fleetComponent", "editFleet");
    },
    selectSingle(fleet) {
      this.$store.dispatch("fleetComponent/setCurrentComponent", "singleFleet");
      sessionStorage.setItem("fleetComponent", "singleFleet");
      sessionStorage.setItem("fleetId", fleet.id);
    },
    newTerminalTab() {
      this.$store.dispatch("fleetComponent/setCurrentComponent", "addFleet");
      sessionStorage.setItem("fleetComponent", "addFleet");
    },
    handleDeleteModal(id) {
      this.fleetId = id;
      this.showDeleteModal = !this.showDeleteModal;
    },
      handleTransferModal(fleet){
        this.showTransferModal = !this.showTransferModal
        this.fleetToTransfer = fleet
      },
      async transferFleet(receivingTerminal){
        console.log(receivingTerminal, "receiving terminal",this.fleetToTransfer)
          if (receivingTerminal.id === this.fleetToTransfer.newDepartureTerminalId){
              this.$displaySnackbar({
                  message: "Selected Fleet currently in this terminal",
                  success: false,
              });
          }
          else {
              this.modalLoading = true
              let data = {}
              data.transportCompanyId= receivingTerminal.transportCompanyId
              data.fleetId= this.fleetToTransfer.id
              data.receivingTerminalId = receivingTerminal.id
              await transferFleet(data).then(res =>{
                  this.$displaySnackbar({
                      message: res.data.detail,
                      success: true,});
                  this.modalLoading = false
                  this.handleTransferModal()
                  this.getAllFleets()
              }).catch(err =>{
                  this.$displaySnackbar({
                      message: err.response.data.message,
                      success: false,
                  });
              }).finally(()=> this.modalLoading = false)
          }
      },
      closeTransferModal(){
        this.showTransferModal = false
      },
    async handleDeleteFleet() {
      await deleteFleet(this.companyId, this.fleetId)
        .then((res) => {
          this.$displaySnackbar({
            message: res.data.Details,
            success: true,
          });
          this.showDeleteModal = !this.showDeleteModal;
          this.loading = true;
          this.getAllFleets();
        })
        .catch((err) => {
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    async getAllFleets() {
        this.loading = true;
      try {
        const response = await getAllCompanyFleets(this.companyData.id);
        response.data = response.data.filter(fleet => !fleet.clone)
        this.fleets = response.data;
        this.fleets.sort(function (a, b) {
          return dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate();
        });
        this.fleets.forEach((fleet, index) => (fleet.sno = index + 1));
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      }
    },
  },
  async created() {

    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
      this.companyId = companyData.id;
    }
    await this.getAllFleets();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  .no-image {
    /* background: rgba(124, 176, 246, 1); */
    width: 32px;
    height: 32px;
    border-radius: 50%;

    p {
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);

      /* text-align: left; */
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0;
  padding-left: 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;
  padding-left: 5px;
  color: #4f4f4f;
}

/* .status {
  font-family: "Poppins", serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 21px;
  color: #4f4f4f;
} */

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
