var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",style:({ color: _vm.companyColor }),on:{"click":_vm.routeBack}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("Fleets")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addFleet)}}},[_c('div',{staticClass:"\n            tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10\n          "},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v(" New Vehicle ")]),(_vm.id)?_c('icons',{attrs:{"name":"trash","color":_vm.companyColor}}):_vm._e()],1),_c('v-row',{staticClass:"upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Upload Photo")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose an image as a profile")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":"blue","indeterminate":""}}):_vm._e(),(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("../../../assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Type")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Type of the Vehicle.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"items":_vm.vehicleTypes,"placeholder":"Choose Type","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},model:{value:(_vm.vehicleType),callback:function ($$v) {_vm.vehicleType=$$v},expression:"vehicleType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Registration")]),_c('p',{staticClass:"label-info"},[_vm._v("Enter Vehicle Registration Number.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle's Registration Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"color":_vm.companyColor,"placeholder":"JJJ-08K4-PP","append-icon":"mdi-chevron-down","solo":"","hide-details":""},model:{value:(_vm.vehicleRegNumber),callback:function ($$v) {_vm.vehicleRegNumber=$$v},expression:"vehicleRegNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Class")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Type of the Vehicle.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle's Class","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"color":_vm.companyColor,"items":_vm.vehicleClasses,"placeholder":"Choose Class","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},model:{value:(_vm.vehicleClass),callback:function ($$v) {_vm.vehicleClass=$$v},expression:"vehicleClass"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Air Condition")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose Vehicle Air Condition Type ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Air Condition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"placeholder":"AC TYPE","solo":"","append-icon":"mdi-chevron-down","hide-details":"","items":_vm.vehicleAcTypes,"type":"text","required":""},model:{value:(_vm.vehicleAcType),callback:function ($$v) {_vm.vehicleAcType=$$v},expression:"vehicleAcType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle Video")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose vehicle video streaming type ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle Video","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"placeholder":"Choose video type","solo":"","append-icon":"mdi-chevron-down","hide-details":"","type":"text","items":_vm.vehicleVideoTypes,"required":""},model:{value:(_vm.vehicleVideoType),callback:function ($$v) {_vm.vehicleVideoType=$$v},expression:"vehicleVideoType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle Brand Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Brand Name of the Vehicle")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Brand Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Telsa","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.vehicleBrand),callback:function ($$v) {_vm.vehicleBrand=$$v},expression:"vehicleBrand"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Vehicle's Capacity")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the no. of passenger the vehicle can accommodate ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Vehicle's Capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"color":_vm.companyColor,"items":_vm.vehicleCaps,"placeholder":"Choose vehicle capacity","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},model:{value:(_vm.vehicleCapacity),callback:function ($$v) {_vm.vehicleCapacity=$$v},expression:"vehicleCapacity"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the Terminal for the Vehicle.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"color":_vm.companyColor,"items":_vm.terminals,"placeholder":"Choose Terminal","append-icon":"mdi-chevron-down","item-text":"terminalName","item-value":"id","solo":"","hide-details":""},model:{value:(_vm.terminalId),callback:function ($$v) {_vm.terminalId=$$v},expression:"terminalId"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"\n            tw-flex tw-w-full tw-justify-end tw-items-center\n            action-btn-div\n            tw-mt-10\n          "},[_c('v-btn',{staticClass:"first-btn",style:({
              color: _vm.companyColor,
              border: '1px solid' + _vm.companyColor,
            }),attrs:{"text":""},on:{"click":_vm.routeBack}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Done ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }