<template>
  <v-col sm="12">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="routeBack"
      :style="{ color: companyColor }"
    >
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4">Fleets</span>
    </button>

    <v-col
      sm="12"
      class="tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full"
    >
      <!-- validation observer -->
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addFleet)">
          <div
            class="
              tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10
            "
          >
            <p class="edit-header" :style="{ color: companyColor }">
              New Vehicle
            </p>
            <icons name="trash" :color="companyColor" v-if="id" />
            <!-- <img src="@/assets/icons/del-blue.svg" alt="" /> -->
          </div>
          <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Upload Photo</p>
                <p class="label-info">Choose an image as a profile</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <button
                class="button-logo-input"
                type="button"
                @click="onLogoFile()"
                :class="image ? 'border-0' : ''"
              >
                <v-progress-circular
                  :size="100"
                  :width="3"
                  color="blue"
                  indeterminate
                  style="position: absolute"
                  v-if="logoImageLoading"
                ></v-progress-circular>

                <img
                  :src="image"
                  alt=""
                  class="button-logo-img"
                  v-if="logoImage"
                />
                <img
                  src="../../../assets/camera.png"
                  alt=""
                  class="button-logo-input-img"
                  v-else
                />
              </button>

              <input
                type="file"
                ref="logoImage"
                accept="image/*"
                style="display: none"
                @change="onLogoImagePicked"
              />
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle's Type</p>
                <p class="label-info">Choose the Type of the Vehicle.</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Type"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-combobox
                    :color="companyColor"
                    :items="vehicleTypes"
                    placeholder="Choose Type"
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    item-value="name"
                    v-model="vehicleType"
                    solo
                    hide-details
                  ></v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle's Registration</p>
                <p class="label-info">Enter Vehicle Registration Number.</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Vehicle's Registration Number"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    :color="companyColor"
                    placeholder="JJJ-08K4-PP"
                    append-icon="mdi-chevron-down"
                    v-model="vehicleRegNumber"
                    solo
                    hide-details
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle's Class</p>
                <p class="label-info">Choose the Type of the Vehicle.</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                  name="Vehicle's Class"
                  rules="required"
                  v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                      :color="companyColor"
                      :items="vehicleClasses"
                      placeholder="Choose Class"
                      append-icon="mdi-chevron-down"
                      item-text="name"
                      item-value="name"
                      v-model="vehicleClass"
                      solo
                      hide-details
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Air Condition</p>
                <p class="label-info">
                  Choose Vehicle Air Condition Type
                </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Air Condition"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                    placeholder="AC TYPE"
                    solo
                    append-icon="mdi-chevron-down"
                    hide-details
                    v-model="vehicleAcType"
                    :items="vehicleAcTypes"
                    type="text"
                    required
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>


          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle Video</p>
                <p class="label-info">Choose vehicle video streaming type </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Vehicle Video"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                    placeholder="Choose video type"
                    solo
                    append-icon="mdi-chevron-down"
                    hide-details
                    type="text"
                    :items="vehicleVideoTypes"
                    v-model="vehicleVideoType"
                    required
                  ></v-select>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle Brand Name</p>
                <p class="label-info">Choose the Brand Name of the Vehicle</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Brand Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    placeholder="Telsa"
                    solo
                    hide-details
                    type="text"
                    v-model="vehicleBrand"
                    required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Vehicle's Capacity</p>
                <p class="label-info">
                  Choose the no. of passenger the vehicle can accommodate
                </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Vehicle's Capacity"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-combobox
                    :color="companyColor"
                    :items="vehicleCaps"
                    placeholder="Choose vehicle capacity"
                    append-icon="mdi-chevron-down"
                    item-text="name"
                    item-value="name"
                    v-model="vehicleCapacity"
                    solo
                    hide-details
                  ></v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Terminal</p>
                <p class="label-info">Choose the Terminal for the Vehicle.</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Terminal"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-select
                    :color="companyColor"
                    :items="terminals"
                    placeholder="Choose Terminal"
                    append-icon="mdi-chevron-down"
                    item-text="terminalName"
                    item-value="id"
                    v-model="terminalId"
                    solo
                    hide-details
                  ></v-select>

                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <div
            class="
              tw-flex tw-w-full tw-justify-end tw-items-center
              action-btn-div
              tw-mt-10
            "
          >
            <v-btn
              @click="routeBack"
              class="first-btn"
              text
              :style="{
                color: companyColor,
                border: '1px solid' + companyColor,
              }"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              type="submit"
              :style="{ background: companyColor }"
              class="second-btn"
              :loading="doneLoading"
            >
              Done
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-col>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getTransportCompanyTerminals,
  addFleet,
} from "../../../services/api/APIService";
import Icons from "../../reuseables/Icons";
export default {
  name: "AddFleet",
  components: { Icons },
  data() {
    return {
      companyData: {},
      doneLoading: false,
      country: "",
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      companyColor: "#004AAD",
      id: null,
      image: "",
      buses: [{ name: "IKY 367 AE" }, { name: "IKY 847 GB" }],
      vehicleTypes: ["BUS", "VAN", "MINIVAN", "COASTER BUS", "LUXURIOUS BUS"],
      vehicleClasses: ["Executive", "Economy", "Coach"],
      vehicleCaps: [8, 12, 14,15, 18, 22, 24,32],
      terminals: [],
      routes: [
        { name: "LAG" },
        { name: "PH" },
        { name: "ABJ" },
        { name: "KAD" },
      ],
      vehicleRegNumber: "",
      vehicleBrand: "",
      vehicleCapacity: "",
      vehicleClass: "",
      vehicleTagNumber: "",
      vehicleImage: "",
      vehicleType: "",
      terminalId: "",
      vehicleAcType:"",
      vehicleAcTypes:["A/C","NON-A/C"],
      vehicleVideoType:"",
      vehicleVideoTypes:["VIDEO","NON-VIDEO"]
    };
  },
  props: {},
  methods: {
    routeBack() {
      this.$store.dispatch("fleetComponent/setCurrentComponent", "allFleets");
      sessionStorage.setItem("fleetComponent", "allFleets");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.vehicleImage = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.country,
        };
        console.log(data);
        const response = await getAllStatesByCountry(data);
        console.log(response);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      try {
        this.getCitiesLoading = true;
        let data = {
          country: this.country,
          state: this.state,
        };

        const response = await getAllCitiesInAState(data);
        console.log(response);
        this.cities = response.data.data;
        this.getCitiesLoading = false;
      } catch (err) {
        console.log(err.response);
        this.cities = [];
        this.getCitiesLoading = false;
      }
    },
    async getAllTerminals() {
      await getTransportCompanyTerminals(this.companyData.id)
        .then((res) => {
          this.terminals = res.data;
          this.loading = false;
          console.log(res.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
    async clearData() {
      (this.vehicleRegNumber = ""),
        (this.vehicleBrand = ""),
        (this.vehicleCapacity = ""),
        (this.vehicleClass = ""),
        (this.vehicleTagNumber = ""),
        (this.vehicleImage = ""),
        (this.vehicleType = "");
    },
    async addFleet() {
      this.doneLoading = true;
      let data = {
        vehicleRegNumber: this.vehicleRegNumber,
        vehicleBrand: this.vehicleBrand,
        vehicleCapacity: this.vehicleCapacity,
        vehicleClass: this.vehicleClass,
        vehicleTagNumber: this.vehicleTagNumber,
        vehicleImage: this.vehicleImage,
        vehicleType: this.vehicleType,
        vehicleACType: this.vehicleAcType,
        vehicleVideoType:this.vehicleVideoType
      };

      try {
        const response = await addFleet(
          data,
          this.companyData.id,
          this.terminalId
        );
        console.log(response);
        await this.clearData();
        this.routeBack();
        this.doneLoading = false;
      } catch (err) {
        console.log(err);
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
        this.doneLoading = false;
      }
    },
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllTerminals();
  },
};
</script>

<style lang="scss" scoped>
.hypen-style {
  border: 2px solid rgba(196, 196, 196, 1);
  width: 17px;
}
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 10px;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
